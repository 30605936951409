import styled from "styled-components";

export const TextLink = styled.span`
  text-decoration: underline;
  text-underline-offset: 2px;
  appearance: none;
  background: none;
  padding: 0px!important;
  cursor: pointer;
  border: 0;

  ${({ primary }) => primary && `color: var(--primary800);`}
  ${({ noUnderline }) => noUnderline && `text-decoration: none;`}
`;
